<template>
  <div style="width: 1200px; margin: 0 auto; padding: 20px">
    <div style="background: #fff; min-height: 600px; padding: 20px">
      <ul>
        您现在的位置：首页->搜索
      </ul>
      <ul style="display: flex; align-items: center; margin: 10px 0">
        关键字:<el-input
          v-model="state.params.Params.Key"
          style="width: 400px; margin: 0 10px"
        />
        <el-button
          type="primary"
          @click="
            state.params.PageIndex = 1;
            state.key = Math.random();
            onLoad();
          "
          >搜索</el-button
        >
        <text style="color: #a2a2a2">(多个关键字请用"空格"隔开)</text>
      </ul>
      <ul style="display: flex; align-items: center">
        系统搜索到约有<text style="font-weight: 600">
          {{ state.totalCount }}
        </text>
        项符合
        {{
          state.params.Params.Key
        }}
        的查询结果
      </ul>
      <div style="margin-top: 20px; cursor: pointer">
        <div
          v-for="item in state.tableData"
          :key="item"
          @click.stop="onNewsClick(item)"
        >
          <ul style="font-weight: 600">
            {{
              item.Title
            }}
          </ul>
          <ul style="line-height: 25px; max-height: 60px; color: #a2a2a2">
            {{
              item.Summary
            }}
          </ul>
          <ul style="line-height: 30px; height: 30px; color: #a2a2a2">
            {{
              item.PublishTime
            }}
          </ul>
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <el-pagination
          small
          background
          layout="prev, pager, next"
          :total="state.totalCount"
          :default-page-size="state.params.PageSize"
          prev-text="上一页"
          next-text="下一页"
          :pageIndex="state.params.PageIndex"
          @current-change="
            (pageIndex) => {
              state.params.PageIndex = pageIndex;
              onLoad();
            }
          "
          :key="state.key"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, onUnmounted, watch } from "vue";
import { home_getnewssearch } from "@/http/api";
import CryptoJS from "crypto-js";
import { secretKey } from "@/utils/const";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
      },
    });
    onMounted(() => {
      state.params.Params.Key = JSON.parse(
        CryptoJS.AES.decrypt(route.params.key, secretKey).toString(
          CryptoJS.enc.Utf8
        )
      );

      //绑定监听事件
      window.addEventListener("keydown", keyDown);
      onLoad();
    });

    const onLoad = () => {
      home_getnewssearch(state.params).then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    watch(
      () => route.params.key,
      (key) => {
        state.params.Params.Key = JSON.parse(
          CryptoJS.AES.decrypt(key, secretKey).toString(CryptoJS.enc.Utf8)
        );
        delete state.tableData;
        home_getnewssearch(state.params).then((res) => {
          if (res.status == 200) {
            const { count, list } = res.data;
            state.tableData = list;
            state.totalCount = count;
          }
        });
      }
    );

    onUnmounted(() => {
      //销毁事件
      window.removeEventListener("keydown", keyDown, false);
    });

    //点击回车键登录
    const keyDown = (e) => {
      if (e.keyCode == 13) {
        onLoad();
      }
    };

    const onNewsClick = (news) => {
      if (news.IsLink) {
        const url = news.LinkUrl;
        if (url.includes("http://") || url.includes("https://"))
          window.open(url, "_blank");
        else window.open("http://" + url, "_blank");
      } else {
        router.push(`/main/c/${news.Id}`);
      }
    };

    return { state, onLoad, onNewsClick };
  },
};
</script>
